import { useState } from "react";
import SideBarContext from "./sidebar-context";

const SidebarProvider = (props) => {
  const [sideBarOpen, setSidebarOpen] = useState(false);

  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const sidebarContext = {
    sideBarOpen,
    closeSidebar,
    openSidebar,
  };

  return (
    <SideBarContext.Provider value={sidebarContext}>
      {props.children}
    </SideBarContext.Provider>
  );
};

export default SidebarProvider;
