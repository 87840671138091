import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/logo.png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../store/redux/userRedux";
import axios from "axios";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [noCred, setNoCred] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (username && password) {
      setNoCred(false);
    } else {
      setNoCred(true);
    }
  }, [username, password]);

  const login = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const { data } = await axios.post(
        "https://app.bmofoundationonline.com/api/auth/login",
        { username, password }
      );

      setLoading(false);
      dispatch(loginUser(data));
      localStorage.setItem("bmo", data.token);
      navigate("/dashboard");
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div className="nk-body bg-white npc-default pg-auth">
      <div className="nk-app-root">
        <div className="nk-main ">
          <div className="nk-wrap nk-wrap-nosidebar">
            <div className="nk-content ">
              <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                <div className="brand-logo pb-4 text-center">
                  <a href="#/" className="logo-link">
                    <img
                      className="logo-light logo-img logo-img-lg"
                      src={logo}
                      alt="logo"
                    />
                    <img
                      className="logo-dark logo-img logo-img-lg"
                      src={logo}
                      alt="logo-dark"
                    />
                  </a>
                </div>
                <div className="card">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title">Admin</h4>
                        <div className="nk-block-des">
                          <p>
                            Access the admin panel using your username and
                            password.
                          </p>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={login}>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="default-01">
                            Email address
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="default-01"
                            placeholder="Enter your username"
                            value={username}
                            onChange={(e) => {
                              setUsername(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Password
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="password"
                            className="form-control form-control-lg"
                            id="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        {loading ? (
                          <button
                            className="btn btn-lg btn-primary btn-block"
                            disabled
                          >
                            Sign in
                          </button>
                        ) : (
                          <button
                            className="btn btn-lg btn-primary btn-block"
                            disabled={noCred}
                            type="submit"
                          >
                            Sign in
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        theme="dark"
        autoClose={3000}
        closeButton={false}
      />
    </div>
  );
};

export default Login;
