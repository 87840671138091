import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Preloader from "../components/Preloader";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Application = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [applicationDetails, setApplicationDetails] = useState({});

  const { id } = useParams();

  useEffect(() => {
    const getDetails = async () => {
      const { data } = await axios.get(
        `https://app.bmofoundationonline.com/api/scholarship/${id}`
      );

      setApplicationDetails(data);
      setPageLoading(false);
    };

    getDetails();
  }, [id]);

  return (
    <>
      {pageLoading ? (
        <Preloader />
      ) : (
        <div className="nk-body bg-lighter npc-general has-sidebar ui-bordered ">
          <div className="nk-app-root">
            <div className="nk-main ">
              <Sidebar />
              <div className="nk-wrap ">
                <Header />
                <div className="nk-content ">
                  <div className="container-fluid">
                    <div className="nk-content-inner">
                      <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                          <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                              <h3 className="nk-block-title page-title">
                                Applications /{" "}
                                <strong className="text-primary small">
                                  {applicationDetails?._id}
                                </strong>
                              </h3>
                            </div>
                            <div className="nk-block-head-content">
                              <Link
                                to="/scholarship-applications"
                                className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                              >
                                <em className="icon ni ni-arrow-left" />
                                <span>Back</span>
                              </Link>
                              <Link
                                to="/scholarship-applications"
                                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                              >
                                <em className="icon ni ni-arrow-left" />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="nk-block">
                          <div className="card">
                            <div className="card-aside-wrap">
                              <div className="card-content">
                                <div className="card-inner">
                                  <div className="nk-block">
                                    <div className="nk-block-head">
                                      <h6 className="title overline-title text-base">
                                        Application Details
                                      </h6>
                                    </div>
                                    <div className="profile-ud-list">
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Application ID
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails._id}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            First Name
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.firstName}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Middle Name
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.middleName ||
                                              "-"}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Last Name
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.lastName}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Date of Birth
                                          </span>
                                          <span className="profile-ud-value">
                                            {new Date(
                                              applicationDetails.dob
                                            ).toLocaleDateString()}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Village
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.village}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Gender
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.gender}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Phone number
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.phone}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Email address
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.email}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Current address
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.address}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Occupation of Parents/Guardian
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.occupation}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            University admitted into
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.university}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Course of Study
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.course}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Accepted?
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.accepted}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Enrolled?
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.enrolled}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Secondary School Attended
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.secondarySchool}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Year Graduated
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.yearGraduated}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            JAMB Score
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.jambScore}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            JAMB Reg Number
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.jambRegNumber}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Year
                                          </span>
                                          <span className="profile-ud-value">
                                            {applicationDetails.jambYear}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="nk-block">
                                    <div className="nk-block-head nk-block-head-line">
                                      <h6 className="title overline-title text-base">
                                        Attachments
                                      </h6>
                                    </div>
                                    <div className="profile-ud-list">
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            JAMB Result
                                          </span>
                                          <span className="profile-ud-value">
                                            <span className="profile-ud-value">
                                              {applicationDetails.jambResult ? (
                                                <a
                                                  href={
                                                    applicationDetails.jambResult
                                                  }
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  View
                                                </a>
                                              ) : (
                                                "-"
                                              )}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="profile-ud-list">
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Admission Letter
                                          </span>
                                          <span className="profile-ud-value">
                                            <span className="profile-ud-value">
                                              {applicationDetails.admissionLetter ? (
                                                <a
                                                  href={
                                                    applicationDetails.admissionLetter
                                                  }
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  View
                                                </a>
                                              ) : (
                                                "-"
                                              )}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="profile-ud-list">
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Application Essay
                                          </span>
                                          <span className="profile-ud-value">
                                            <span className="profile-ud-value">
                                              {applicationDetails.applicationEssay ? (
                                                <a
                                                  href={
                                                    applicationDetails.applicationEssay
                                                  }
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  View
                                                </a>
                                              ) : (
                                                "-"
                                              )}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="profile-ud-list">
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            O’Level Result
                                          </span>
                                          <span className="profile-ud-value">
                                            <span className="profile-ud-value">
                                              {applicationDetails.oLevelResult ? (
                                                <a
                                                  href={
                                                    applicationDetails.oLevelResult
                                                  }
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  View
                                                </a>
                                              ) : (
                                                "-"
                                              )}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="profile-ud-list">
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            Other relevant doc
                                          </span>
                                          <span className="profile-ud-value">
                                            <span className="profile-ud-value">
                                              {applicationDetails.other ? (
                                                <a
                                                  href={
                                                    applicationDetails.other
                                                  }
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  View
                                                </a>
                                              ) : (
                                                "-"
                                              )}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="nk-divider divider md" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Application;
