import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../store/redux/userRedux";
import SideBarContext from "../store/context/sidebar-context";

const Header = () => {
  const { openSidebar } = useContext(SideBarContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    localStorage.removeItem("bmo");
    dispatch(logoutUser());
    navigate("/");
  };

  return (
    <div className="nk-header nk-header-fixed is-light">
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ms-n1">
            <a
              href="#/"
              className="nk-nav-toggle nk-quick-nav-icon"
              onClick={openSidebar}
            >
              <em className="icon ni ni-menu" />
            </a>
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li className="dropdown user-dropdown">
                <a
                  href="#/"
                  className="dropdown-toggle me-n1"
                  data-bs-toggle="dropdown"
                >
                  <div className="user-toggle">
                    <div className="user-avatar sm">
                      <em className="icon ni ni-user-alt" />
                    </div>
                    <div className="user-info d-none d-xl-block">
                      <div className="user-status user-status-active">
                        Administrator
                      </div>
                    </div>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-md dropdown-menu-end">
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li>
                        <a href="#/" onClick={logoutHandler}>
                          <em className="icon ni ni-signout" />
                          <span>Sign out</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
