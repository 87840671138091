import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import { useEffect } from "react";
import axios from "axios";
import {
  loadUser,
  loadingUser,
  logoutUser,
  noUser,
} from "./store/redux/userRedux";
import { useDispatch, useSelector } from "react-redux";
import Donations from "./pages/Donations";
import MailingList from "./pages/MailingList";
import Messages from "./pages/Messages";
import ScholarshipApplications from "./pages/ScholarshipApplications";
import Application from "./pages/Application";

function App() {
  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingUser());
    const token = localStorage.getItem("bmo");

    const getUser = async () => {
      try {
        const { data } = await axios.get(
          "https://app.bmofoundationonline.com/api/admin",
          {
            headers: { token: `Bearer ${token}` },
          }
        );

        dispatch(loadUser(data));
      } catch (error) {
        if (error.response.data === "Token is not valid!") {
          localStorage.removeItem("bmo");
          dispatch(logoutUser());
        }
      }
    };

    if (token) {
      getUser();
    } else {
      dispatch(noUser());
    }
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={user ? <Dashboard /> : <Login />} />
      <Route
        path="/dashboard"
        element={user ? <Dashboard /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/donations"
        element={user ? <Donations /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/messages"
        element={user ? <Messages /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/mailing-list"
        element={user ? <MailingList /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/scholarship-applications"
        element={
          user ? <ScholarshipApplications /> : <Navigate replace to={"/"} />
        }
      />
      <Route
        path="/scholarship-application/:id"
        element={user ? <Application /> : <Navigate replace to={"/"} />}
      />
    </Routes>
  );
}

export default App;
